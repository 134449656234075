@import url('https://fonts.googleapis.com/css?family=Inter');


main {
  overflow-x: hidden;
  display: flex;
  position: sticky;
  top:50px;
}
:root{
  --bs-font-inter: 'Inter';
}
body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-body-font-family: var(--bs-font-inter);

}

code {
  font-family: 'Inter';
}

.pt-20 {
  padding-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.font-weight-600 {
  font-weight: 700;
}

.paddingLR-100 {
  padding: 0px 100px;
}

.paddingLR-40 {
  padding-left: 60px;
  padding-right: 60px;
}

.text-gray{
  color: #949494;
}

.ReactModal__Body--open {
  height: 100vh;
  overflow-y: hidden;
}

.ReactModal__Overlay{
  background-color: rgba(0, 0, 0, 0.45) !important;
  backdrop-filter: blur(2px) !important;
  z-index: 9999;
  cursor: default;
  pointer-events: none;
}

.ReactModal__Content {
 position: fixed;
 top: 35% !important;
 left: 50% !important;
 right: auto !important;
 bottom: auto !important;
 margin-right: -50% !important;
 border-radius: 20px !important;
 transform: translate(-50%, -50%) !important;
 width: 500px !important;
 outline: none !important;
}

.login-overlay {
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  cursor: default;
  pointer-events: none;
  border-radius: 10px;
}

.login-overlay input {
  cursor: default;
  pointer-events: auto;
  padding: 0.725rem 1.125rem;
  font-size: 16px;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #000 !important;
}

.login-overlay button{
  cursor: pointer;
  pointer-events: auto;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 1.5rem;
}

.forgot-password-btn {
  background-color: transparent;
  outline: none;
  border: none;
  color: #f14c38;
  font-size: 16px;
  padding: 0;
}

.otp-timer{
  color: #f14c38;
}

.g-recaptcha > div > div > div {
  margin-top: 15px;
  transform: scale(0.9);
  margin-left: -15px;
  pointer-events: auto;
  cursor: default;
  
}
.btn-link[disabled] {
  color: #c4baba !important;
}
.otp-input > div {
  display: flex;
  justify-content: center;
}

.primary_color {
  color: #146B90 !important;
}

a.text-decoration-none {
  text-decoration: none !important;
}

.Toastify__toast-container {     z-index: 99999 !important; }

.Toastify__toast-container--top-right {
  top: 6em !important;
 }

.offering-section {
  min-width: 100vw;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .offering-section:nth-child(even) {
  background-color: teal;
  color: white;
} */

.news-media{
  background-color: #F8F6F0;
  padding: 5rem 0;
}

.news-media .card-details{
  font-size: 17px;
  line-height: 1.4;
  margin: 1rem 0rem;
  height: auto;
}

.news-media .pagination button {
  margin: 0rem 0.2rem;
  width: 30px;
  height: 30px;
  outline: none;
  color: #949494;
  border: 1px solid #949494;
  border-radius: 0.25rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-media .pagination button.active {
  border: 1px solid #146B90;
  color: #fff;
  background-color: #146B90;
}

marquee p{ 
  display: flex; 
  align-items: center; 
  z-index: 1;}

marquee span.marquee-content{
  margin-right: 85%;
}

marquee span{
  display: flex; 
  align-items: center; 
  flex-wrap: nowrap;
  flex-direction: row;
}

.shine-element {
  width: 50px;
  height: 60px;
  margin-top: -70px;
  background: rgba(255,255,255,0.3);
  position: relative;
  z-index: 9999999;
  transform: skew(-45deg);
  animation: move 5s infinite;
}

@keyframes move {
  from {left: 0%;}
  to {left: 100%;}
}

@media only screen and (max-width: 441px){
.otp-input input {
  width: 40px !important;
  height: 40px !important;
  margin: 6px !important;
  font-size: 14px !important;
}
.client-logo {
  padding: 16px 10px !important;
}
.nav-item .nav-link {
  padding: 0px 0px 16px 16px !important;
}
}

@media only screen and (max-width: 600px) {
  .paddingLR-100 {
    padding: 0px 10px;
  }
}

@media only screen and (max-width: 768px)
{
  .ReactModal__Content {
    width: 98% !important;
    margin: 0 auto;
    top: 50% !important;
    left: 50% !important;
}
.Toastify__toast-container--top-right {
  top: 2em !important;
 }
}

@media only screen and (min-width: 1200px){
  .card-text {
    font-size: 12px !important;
  }
  .news-media .card-details{
    font-size: 14px !important;
    min-height: 40px !important;
  }
}

@media only screen and (min-width: 1440px){
  .card-text {
    font-size: 15px !important;
  }
  .news-media .card-details{
    font-size: 16px !important;
    min-height: 60px !important;
  }
}