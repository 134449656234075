
.logo-main {
  width: 150px;
  height: auto;
}

.responsive-bar{
  display: none;
}

/* nav{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 85px;
  padding: 10px 125px;
  box-sizing: border-box;
  transition: .5s;
}

nav.black{
  background: #FFFFFF;
  height: 55px;
  padding: 10px 100px;
  z-index: 9999;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
} */
.fixed-top {
  background-color: #fff;

}

nav .logo{
  float: left;
}

nav .logo {
  display: block;
}

nav .mb-logo{
  display: none;
}

nav .logo img{
  transition: .5s;
}

nav.black .mb-logo{
  display: block;
  float: left;
}

.logo-overlap {
  width: 62px;
  height: 37px;
}

nav.black .logo{
  display: none;
}

nav.black .mb-logo {
  height: 55px;
}

nav > ul{
  margin: 0 auto;
  padding: 0;
  float: right;
  margin-top: -13px;
  display: flex;
  align-items: center;
}

nav > ul > li{
  list-style: none;
  display: inline-block;
}

ul.navbar-nav>li:last-child a button {
  margin-left: 0rem;
  padding: 6px 40px;
}

/* 
li.active {
} */

nav > ul > li > a{
  color: #303030;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-decoration: none;
  line-height: 80px;
  padding: 5px 20px;
  transition: .5s;   
}

nav.black > ul > li > a{
  line-height: 60px;
}

ul.navbar-nav li.active a.nav-link{
  color: #ED483D;
  font-weight: 700;
}

nav.black > ul {
  margin-top: -13px;
}

.home{
  height: 100vh;
}

.g-recaptcha > div > div > div {
  margin: 0 auto;
}

.sticky-container.home .carousel{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
}

.carousel-control-next {
  right: 15px !important;
}

.carousel-control-prev {
  left: 15px !important;
}

@media(max-width:1440px){
  .offering-section {
    padding: 0px 5rem;
 }
}

@media(max-width:1200px){
  .offering-section {
    padding: 0px 5rem;
 }
}

@media(max-width:768px){
  .responsive-bar{
      display: block;
      padding: 20px 10px;
  }
  .responsive-bar .logo img{
      float: left;
      width: 160px;
      height: 65px;
  }

  .responsive-bar .menu {
      float: right;
      margin-top: -55px;
      padding: 10px;
  }

  nav{
      padding: 0;
  }
  nav, nav.black{
    display: none;
  }
  nav .logo{
      display: none;
  }
}


@media only screen and (min-width:1600px) {

  nav{
    padding: 20px 120px;
  }

  nav > ul > li > a {
    font-size: 20px;
  }

	.offering-section .container {
   /* max-width: 1450px; */
    width: 100%;
  }

  .offering-section {
    min-height: 100vh;
  }

  .offering-section .home-head { 
    font-size: 20px;
    padding-top: 130px;
  }

  .offering-section .home-head-highlight-1 {
    font-size: 20px;
  }

  .offering-section .home-title {
    font-size: 30px;
    line-height: 46px;
    margin-top: 72px;
  }

  .offering-section .home-subtitle {
    font-size: 18px;
    padding-top: 32px;
  }

  .offering-section .home-images {
    padding-top: 30px;
  }

  .offering-section .active-inclu {  margin-left: 150px;}

  .offering-section .active-belon {
    margin-left: 300px;
  }

  .inclusional .container {
    max-width: 1450px;
    width: 100%;
  }

  .inclusional .inclusional-sub-title { 
    font-size: 18px;
    margin-bottom: 16px;
  }

  .inclusional .inclusional-title {
    font-size: 42px;
    margin-bottom: 40px;
  }

  .inclusional .inclusional-para {
    font-size: 20px;
  }

  .inclusional .flow-img {
    padding: 30px 0px;
  } 

  .inclusional .inclusional-img {
    width: 100%;
    height: 750px;
  }



  .offer .offer-slide-title {
    font-size: 18px;
  }

  .offer .offer-slider-heading {
    font-size: 34px;
    padding-top: 42px;
    margin-bottom: 15px;
    line-height: 50px;
  }

  .offer .offer-slider-para {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 18px;
  }

  .offer .offer-text-decorate {
    font-size: 18px;
    padding: 3px 7px;
  }

  .offer .offer-tag {
    margin-top: 20px;
  }


  .posh .container {
    max-width: 1450px;
    width: 100%;
  }


  .posh .posh-center {
    padding: 0px 100px;
  }

  .posh .posh-partner {
    font-size: 20px;
  }

  .posh .posh-title {
    font-size: 46x;
    line-height: 60px;
    margin-bottom: 20px;
  }

  .posh .posh-para {
    font-size: 18px;
    line-height: 34px;
    margin-bottom: 24px;
  }

  .customers .client-comment {
    font-size: 18px;
  }


  .team .container {
    max-width: 1450px;
    width: 100%;
  }

  .customers .container {
    max-width: 1450px;
    width: 100%;
  }

  .contact-us  .container {
    max-width: 1450px;
    width: 100%;
  }

  .footer  .container {
    max-width: 1450px;
    width: 100%;
  }
  
}

@media only screen and (min-width:2000px) {

  nav{
    padding: 20px 160px;
  }

  .logo-main {
    width: 297px;
    height: 120px;
  }

  nav > ul > li > a {
    font-size: 20px;
  }

	.offering-section .container {
    max-width: 1765px;
    width: 100%;
  }

  .offering-section {
    min-height: 100vh;
  }

  .offering-section .home-head { 
    font-size: 24px;
    padding-top: 130px;
  }

  .offering-section .home-head-highlight-1 {
    font-size: 24px;
  }

  .offering-section .home-title {
    font-size: 32px;
    line-height: 46px;
    margin-top: 72px;
  }

  .offering-section .home-subtitle {
    font-size: 18px;
    padding-top: 32px;
  }

  .offering-section .home-images {
    padding-top: 30px;
  }

  .offering-section .active-inclu {  margin-left: 165px;}

  .offering-section .active-belon {
    margin-left: 342px;
  }

  .slanted-div {
    padding: 188px 0 !important;
  }

  .inclusional .container {
    max-width: 1765px;
    width: 100%;
  }

  .inclusional .inclusional-sub-title { 
    font-size: 20px;
    margin-bottom: 16px;
  }

  .inclusional .inclusional-title {
    font-size: 48px;
    margin-bottom: 40px;
  }

  .inclusional .inclusional-para {
    font-size: 20px;
  }

  .inclusional .flow-img {
    padding: 30px 0px;
  } 

  .inclusional .inclusional-img {
    width: 100%;
    height: 860px;
  }

  .offer .offer-slide-title {
    font-size: 20px;
  }

  .offer .offer-slider-heading {
    font-size: 48px;
    padding-top: 64px;
    margin-bottom: 20px;
    line-height: 64px;
  }

  .offer .offer-slider-para {
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 20px;
  }

  .offer .offer-text-decorate {
    font-size: 20px;
    padding: 3px 7px;
  }

  .offer .offer-tag {
    margin-top: 55px;
  }


	.posh .container {
    max-width: 1765px;
    width: 100%;
  }


  .posh .posh-center {
    padding: 0px 140px;
  }

  .posh .posh-partner {
    font-size: 20px;
  }

  .posh .posh-title {
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 22px;
  }

  .posh .posh-para {
    font-size: 20px;
    line-height: 44px;
    margin-bottom: 30px;
  }

  .team .container {
    max-width: 1765px;
    width: 100%;
  }

  .team {
    padding: 162px 220px 103px !important;
  }

  .team .team-subtitle {
    font-size: 20px;
  }

  .team .team-title {
    font-size: 48px;
    margin-bottom: 37px;
  }

  .team .card-title { 
    font-size: 18px;
  }

  .team .card-text { 
    font-size: 14px;
  }

  .team .card-details { 
    font-size: 14px;
  }

  .customers {
    padding: 160px 0px 80px !important;
  }

  .customers .container {
    max-width: 1765px;
    width: 100%;
  }

  .customers .customer-subtitle {
    font-size: 20px;
    margin-bottom: 17px;
  }

  .customers .customer-title {
    font-size: 46px;
    margin-bottom: 70px;
  }

  .customers .client-name {
    font-size: 34px;
    margin-top: 28px;
    margin-bottom: 8px;

  }

  .customers .client-designation {
    font-size: 18px;
    margin-top: 18px;
  }

  .customers .client-comment {
    font-size: 20px;
  }

  .customers .colon_img {
    height: 70px;
  }

  .customers .colon_2_img {
    height: 120px;
  }

  .contact-us  .container {
    max-width: 1765px;
    width: 100%;
  }
  
  .contact-us {
    padding: 100px !important;
  }

  .contact-us .contact-title {
    font-size: 40px;
  }

  .contact-us .contact-para {
    font-size: 20px;
  }

  .contact-us .lets-connect {
    padding: 26px 48px;
  }

  .contact-us .form-group {
    margin-bottom: 22px;
  }

  .footer {
    padding: 86px !important;
  }

  .footer  .container {
    max-width: 1765px;
    width: 100%;
  }

  .footer .footer-link {
    font-size: 20px;
  }

  .footer .social-icon {
    width: 48px;
    height: 48px;
    margin-right: 25px;
  }

  .footer .footer-copyright {
    font-size: 18px;
  }

  .footer .footer-logo-jsi {
    width: 241px;
    height: 91px;
  }

  .footer .footer-logo {
    width: 342px;
    height: 131px;
  }
}
 



.red {
  color: #ED483D !important;

}

.nav-link { 
  color: #303030;
  font-size: 16px !important;
  padding: 0px 0px 0px 30px !important;
}

.navbar-toggler {
  border: none !important;
}

/* .home {
  height: 630px;
  margin-top: 100px;
} */

.home-highlight-1 {
  color: #ED483D;
  font-weight: 700;
}

.home-highlight-2 {
  color: #22A298;
  font-weight: 700;
}

.home-highlight-3 {
  color: #F4B532;
  font-weight: 700;
}

.home-head {
  font-weight: 700;
  font-size: 16px;
  line-height: 29.05px;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.2);
  padding-top: 100px;
  letter-spacing: 4px;
}

.home-head-highlight-1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 29.05px;
  margin-bottom: 5px;
  color: #000000;
  letter-spacing: 4px;
}

.home-images {
  width: 100%;
  padding-top: 30px;
  height: 100%;
}

.home-title {
  line-height: 40px;
  font-weight: 400;
  font-size: 24px;
  color: #000;
  margin-top: 45px;
}

.home-subtitle {
  line-height: 21.78px;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  padding-top: 15px;
}

.active-diver {
  width: 28px;
  height: 8px;
  background-color: #ED483D;
  border-radius: 21px;
}

.active-inclu {
  width: 28px;
  height: 8px;
  background-color: #22A298;
  border-radius: 21px;
  margin-left: 128px;
}

.active-belon {
  width: 28px;
  height: 8px;
  background-color: #F4B532;
  border-radius: 21px;
  margin-left: 255px;

}

.inclusional {
  background-color: #F8F6F0;
  padding: 100px 0px;

}

.inclusional-sub-title {
  text-align: center;
  letter-spacing: 0.39em;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 12px;
}

.inclusional-title {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 30px;
}

.inclusional-para {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
}

.flow-img {
  padding: 30px 40px !important;
  width: 100%;
}

.map-title {
  text-align: center;
  margin-top: 70px !important;
  margin-bottom: 40px;
  font-size: 22px;
  font-weight: 700;
}

.inclusional-img {
  width: 100%;
  height: 520px;
}

.offer{
  /* height: 650px !important; */
}

.offer-height {
  height: 600px !important;
}

.offer-green {
  background-color: #22A298;
}

.offer-blue {
  background-color: #146B90;
}

.offer-pink {
  background-color: #F59390;
}

.offer-slider-img {
  width: 100%;
  height: 100%;
}

.offer-text {
  padding: 80px 0px 80px 100px !important;
}

.offer-slide-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24.2px;
  letter-spacing: 0.39em;
  color: #FFFFFF;
}

.offer-slider-heading {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #FFFFFF;
  padding-top: 40px;

}

.offer-slider-para {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.offer-text-decorate {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  padding: 2px 5px;
}

.offer-tag {
  width: 100%;
  margin-left: -5px;
}

.posh {
  padding: 130px 0px;
}

.posh-center {
  padding: 0px 40px;
}

.posh-img {
  width: 100%;
  padding-left: 20px;
}

.posh-partner {
  letter-spacing: 0.39em;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
}

.posh-title {
  font-size: 40px;
  line-height: 54px;
  color: #000;
  font-weight: 600;
}

.posh-para {
  line-height: 30px;
  font-weight: 400;
  color: #000;
  font-size: 16px;
}

.team {
  background-color: #F8F6F0;
  padding: 100px 0px 70px;

}

.team_1 {
  background-color: #E64539;
}

.team_2 {
  background-color: #219C8F;
  ;
}

.team_3 {
  background-color:#F4B532;

}

.team-subtitle {
  text-align: center;
  letter-spacing: 0.41em;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
}

.team-title {
  text-align: center;
  line-height: 64px;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.footer-team {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin-top: 40px;
}

.team-jet-logo {
  width: 200px;
  height: 37px;
  padding: 1px 7px;
}

.card-title {
  color: #282828;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.card-text {
  color: #3E3E59;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

}

.card-details {
  color: #5F6980;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  height: 60px;
  margin-top: -5px;
}



.customers {
  background-color: #fff;
  padding: 100px 0px 70px;

}

.customer-subtitle {
  color: #000000;
  text-align: center;
  letter-spacing: 0.39em;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: 34px;

}

.customer-title {
  color: #000000;
  text-align: center;
  line-height: 64px;
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 40px;
}

.client-logo {
  padding: 0px 40px;
}

.client-name {
  font-weight: 700;
  font-size: 22px;
  line-height: 41.15px;
  margin-top: 8px;
}

.client-designation {
  font-weight: 700;
  font-size: 14px;
  line-height: 0px;
  color: #949494;
}

.client-comment {
  text-align: justify;
  margin-top: 40px;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding: 0px 10px;
  line-height: 29.8px;
}

.colon_img {
  margin-top: 40px;
  width: 50px;
  height: 40px;
}

.colon_2_img {
  margin-top: 110px;
  width: 50px;
  height: 40px;
}

.customer-bar {
  background-color: #D9D9D9 !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50px;
  border-top: 0px solid transparent !important;
  border-bottom: 0px solid transparent !important;
}

.carousel-indicators .active {
  background-color: #22A298 !important;
  width: 28px !important;
  height: 8px !important;
  border-radius: 21px;
  border-top: 0px solid transparent !important;
  border-bottom: 0px solid transparent !important;
}

.testimonial__inner {
  margin-top: 50px;
}

.testimonial{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cors-mb {
  margin-bottom: -40px !important;
}

.client-logo-1 {
  padding-top: 28px;
}

.client-logo-2 {
  margin-top: -12px;
  margin-left: 25px;
}

.client-logo-3 {
  margin-top: 25px;
}

.client-logo-4 {
  margin-top: 20px;
}

.client-logo-5 {
  margin-left: 100px;
  height: 105px;
  margin-top: 20px;

}


.contact-us {
  background-color: #F4B532;
  padding: 70px;
}

.contact-para {
  color: #FFFFFF;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.40em;
  font-weight: 500;
}

.contact-title {
  font-size: 32px;
  font-weight: 600 !important;
  line-height: 45px;
}

.text-white {
  color: #FFFFFF;
}

.contact-img {
  width: 100%;
  margin-top: 57px;
}

.lets-connect {
  border-radius: 10px;
  background-color: white !important;
  padding: 20px;
}

.contact-note {
  font-size: 12px;
  text-align: center;
  color: rgba(102, 102, 102, 0.93);
}

.contact-term {
  text-decoration: underline;
  font-weight: 600;
  color: rgba(102, 102, 102, 0.93);
}

.btn_color {
  background-color: #146B90 !important;
  width: 100%;
}

.success-msg {
  display: block;
  border-radius: 10px;
  background-color: white !important;
  padding: 20px;
}

.contact-reform {
  font-size: 14px;
  text-decoration: underline;
  color: #000;
  text-align: center;
  padding-top: 30px;
  cursor: pointer;
}

.btn-primary {
   --bs-btn-color: #fff;
   --bs-btn-bg: #146B90 !important;
   --bs-btn-border-color: #146B90 !important;
   --bs-btn-hover-color: #fff;
   --bs-btn-hover-bg: #146B90 !important;
   --bs-btn-hover-border-color: #146B90  !important;
   --bs-btn-focus-shadow-rgb: 49,132,253;
   --bs-btn-active-color: #fff;
   --bs-btn-active-bg: #146B90  !important;
   --bs-btn-active-border-color: #146B90  !important;
   --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --bs-btn-disabled-color: #fff;
   --bs-btn-disabled-bg: #146B90  !important;
   --bs-btn-disabled-border-color: #146B90 !important;
}

.form-control {
  border: 1px solid #79747E;
}

label {
  line-height: 25px;
  font-size: 15px;
}

::placeholder {
  font-size: 14px;
  color: #49454F;
}

.footer {
  padding: 22px;
  background-color: #F8F6F0 !important;
  height: auto;
}

.social-media {
  float: left;
}

.social-icon {
  width: 28px;
  height: 28px;
  margin-right: 25px;
}

.footer-copyright {
  color: #5A5A5A;
  font-size: 12px;
  letter-spacing: 1px;
}

.footer-link {
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  margin-right: 25px;
  letter-spacing: 0.5px;
}

.logo-media {
  float: right;
}

.footer-logo{
  width: 200px;
  height: 80px;
  margin-top: 15px;
}

.footer-logo-jsi {
  width: 175px;
  height: 70px;
  margin-top: 15px;
  margin-right: 40px;
  padding: 5px;
}

.mb-social-icon {
  display: none;
}

.mb-social-media {
  display: none;
}

.mb-footer-link {
  display: none;
}

.mobile-display {
  display: none;
}

.mobile-none {
  display: block; 
}

.offer-slide-3 {
  width: 28px;
  height: 5px;
  background-color: #FFFFFF;
  border-radius: 21px;
  float: left;
  margin-right: 4px;
  margin-bottom: 10px;
  margin-top: -8px;
}

.offer-slide {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 21px;
  float: left;
  margin-right: 4px;
  margin-bottom: 10px;
  margin-top: -8px;
}

@media only screen and (max-width: 600px) {
  .paddingLR-100 {
    padding: 0px 40px;
  }

  .logo {
    width: 160px;
    height: 64px;
  }

  .home {
    padding: 0px 0px 10px;
    margin-top: 0px;
  }

  .home-images {
    width: 100%;
    padding-left: 0px !important;
    height: 300px;
  }

  .home-head {
    font-weight: 700;
    font-size: 14px;
    line-height: 29.05px;
    margin-bottom: 0px;
    letter-spacing: 2px;
    padding-top: 45px;
  }
  
  .home-head-highlight-1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 29.05px;
    margin-bottom: 0px;
    letter-spacing: 2px;
  }

  .active-diver {
    width: 15px;
    height: 5px;
    border-radius: 16px;
  }

  .active-inclu {
    width: 15px;
    height: 5px;
    border-radius: 16px;
    margin-left: 95px;
  }
  
  .active-belon {
    width: 15px;
    height: 5px;
    border-radius: 16px;
    margin-left: 190px;
  }

  .home-highlight-1 {
    font-size: 18px;
  }
  
  .home-highlight-2 {
    font-size: 18px;
  }
  
  .home-highlight-3 {
    font-size: 18px;
  }

  .home-title {
    line-height: 30px;
    font-weight: 400;
    font-size: 18px;
    color: #000;
    margin-top: 25px;
  }

  .inclusional {
    background-color: #F8F6F0;
    padding: 100px 0px;
    margin-top: 100px;
  }

  .inclusional-para {
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .inclusional-img {
    width: 100%;
    height: 200px;
  }

  .flow-img {
    padding: 20px 40px !important;
    width: 100%;
  }

  .offer {
    height: 1130px !important;
  }

  .offer-height {
    height: 1130px !important;
  }

  .offer-text {
    padding: 0px 5px 50px 30px !important;
  }

  .offer-text-decorate {
    border: 0px solid #FFFFFF;
  }

  .posh-partner {
    margin-top: 24px;;
  }

  .posh-title {
    font-size: 32px;
    line-height: 44px;
    color: #000;
    font-weight: 700;
  }

  .posh-img {
    width: 100%;
    padding-left: 0px;
  }

  .team-subtitle {
    margin-bottom: 10px;
  }

  .team-title {
    line-height: 40px;
    font-size: 32px;
  }

  .team-space {
    margin-bottom: 26px;
  }
  .card-details {
    height: 70px;
  }
  .team-jet-logo {
    width: 85px;
    height: 16px;
  }

  .customer-title {
    color: #000000;
    text-align: center;
    line-height: 40px;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 10px;
  }

  .client-comment {
    text-align: center;
    margin-top: 30px;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 0px 10px;
    line-height: 24px;
    padding-bottom: 40px;
  }

  .client-logo-1 {
    margin-left: 70px;
  }

  .client-logo-2 {
    margin-left: 80px;
  }

  .client-logo-3 {
    margin-left: 70px;
  }

  .client-logo-4 {
    margin-left: 60px;
  }

  .offer-slide-3 {
    width: 15px;
    height: 5px;
    background-color: #FFFFFF;
    border-radius: 11px;
    float: left;
    margin-right: 4px;
    margin-bottom: 10px;
    margin-top: -8px;
  }
  
  .offer-slide {
    width: 5px;
    height: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 11px;
    float: left;
    margin-right: 4px;
    margin-bottom: 10px;
    margin-top: -8px;
  }

  .footer-team {
    font-size: 14px;
    margin-top: 36px;
  }

  .contact-us {
    padding: 50px 10px;
  }

  .mobile-mb {
    margin-bottom: 25px;
  }

  .contact-title {
    font-size: 30px;
    font-weight: 600 !important;
    line-height: 40px;
  }

  .contact-img {
    margin-top: 24px;
  }

  .social-media {
    display: none;
  }

  .mb-social-media {
    text-align: center;
    display: block;
  }

  .mobile-display {
    display: block;
  }

  .mobile-none {
    display: none !important; 
  }

  .mb-social-icon {
    display: block;
    width: 40px;
    margin-right: 20px;
    margin-top: 8px;
    float: left;
  }

  .footer {
    padding: 22px 0px;
    height: auto;
  }

  .mb-footer-link {
    display: block;
    text-align: center;
    padding-top: 20px;
  }

  .footer-copyright {
    text-align: center;
    margin-top: -8px;
  }

  .footer-link {
    margin-right: 0px;
  }

  .logo-media {
    float: none;
    text-align: center;
  }
  .social-link{
    margin-right: 20px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .footer-logo-jsi {
    width: 120px;
    height: 45px;
    margin-top: 0px;
    margin-right: 35px;
    padding: 0px;
  }

  .footer-logo {
    width: 140px;
    height: 55px;
    margin-top: 0px;
  }
  .terms {
    padding: 20px 40px !important;
    text-align: justify;
  }
  
  .mob-icon {
    width: 40px;
    height: 40px;
  }

  .social-mr {
    margin-bottom: 0px;
    margin-top: 5px;
  }
}

.text-center {
  text-align: center;
}

.terms {
  padding: 50px 100px;
  text-align: justify;
}

.error {
  color: red;
}


.slanted-div {
  position: relative;
  padding: 100px 0;
  overflow: visible;
  z-index: 1;
}





.slanted-div.style1:before, .slanted-div.style1:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  transform-origin: left top;
  transform: skewY(-2deg);
}

.carousel .control-next.control-arrow:before {
  transform: rotate(135deg);
}

.carousel .control-prev.control-arrow:before {
  transform: rotate(-45deg);
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgb(0 0 0 / 0%) !important;
}

.carousel .carousel-status {
  display: none;
}

.carousel .thumbs {
    display: none;

}
.client-image {
  width: 155px !important;
  height: 155px;
}
.carousel.carousel-slider .control-arrow {
  color: #000 !important;
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  /* margin: 0 5px; */
  /* display: inline-block; */
  /* border-top: 8px solid transparent; */
  border-bottom: 0px solid transparent !important;
  content: '';
  display: block;
  margin: 0px auto;
  width: 20px;
  height: 20px;
  border-top: 2px solid #000 !important;
  border-left: 2px solid #000 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 0px solid #000 !important;
}

.carousel .control-dots {
  margin: 0px 0px 0px !important;
}

.control-dots > .selected {
  background-color: #22A298 !important;
  width: 25px !important;
  height: 7px !important;
  border-radius: 21px !important;
  border-top: 0px solid transparent !important;
  border-bottom: 0px solid transparent !important;
  box-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
  padding-top: 5px !important;
}

.carousel .control-dots .dot {
  margin: -5px 5px !important;
}

.carousel .control-dots .dot {
  background: #bcb8b8;
}

.carousel-indicators [data-bs-target]{
  display: inline-block;
  zoom: 1;
  float: none;
  background-color: #303030;
  background-color: rgba(0,0,0,.5);
  cursor: pointer;
  text-align: center;
}


.top-indicators {
  bottom: 70px !important;
  /* width: 100%;
  margin: 0 auto; */
}

.black-color {
  color: #000 !important;
  font-size: 34px;
}

#demo .carousel-control .fa {
  font-size: 30px;
  color: var(--theme-text);
  margin-top: 24px;
  font-weight: 600;
  text-shadow: none!important;
}

.carousel-control-next, .carousel-control-prev {
  width: 4% !important;
}

#demo .carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000; 

}

.carousel-indicators .active {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  margin-top: 10px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #79747E; 
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
  border-radius: 21px !important;
}

@media (max-width: 768px){
.top-indicators {
    bottom: 0px !important;
}
}