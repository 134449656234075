.bg-login {
    background-image: url('../../../public/images/login_background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logo {
    padding-top: 30px;
    padding-left: 57px;
    width: 230px;
    height: auto;
    position: absolute;
    left: 0;
    top: 10px;
}

.login-bottom {
    border-bottom: 1px solid #E1E1E1;
}

.login-title {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.957px;
}

.login-details p {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.login-otp .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #146B90;
    --bs-btn-border-color: #146B90;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #146B90;
    --bs-btn-hover-border-color: #146B90;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146B90;
    --bs-btn-active-border-color: #146B90;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #146B90;
    --bs-btn-disabled-border-color: #146B90;
}

.login-otp .btn {
    --bs-border-radius: 4px;
}

.login-otpinput {
    padding-left: 25px;
}

.login-resend {
    float: none;
    color: #146B90;
    font-size: 18px;
}

.login-time {
    float: none;
    font-size: 20px;
}

.resend-otp {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp-btn {
    width: 75%;
    margin-bottom: 8px;
    margin-top: 85px;
    height: 56px;
}

.login-note {
    font-size: 14px;
    text-align: center;
    color: #666666EE;
    margin-left: 20px;
}

.login-term {
    font-weight: 700;
    text-decoration: underline;
}

.login-footer {
    padding: 0px 50px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-copyright {
    font-size: 16px;
    color: #5A5A5A;
    letter-spacing: 1.33px;
    line-height: 35px;
}

.login-link {
    color: #000;
    text-decoration: none;
    margin-right: 25px;
    font-size: 16px;
    line-height: 25px;
}

.login-social-media {
    float: right;
}

.login-social-icons {
    width: 34px;
    margin-left: 20px;
}

.login-back {
    position: absolute;
    left: 10px;
    top: 0px;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 2rem;
}